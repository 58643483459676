import { logout } from '../api/auth';

export const onError = (error, auth) => {
  if (error === 401) {
    // bugsnag?
    console.log('Thinking of logging out because of 401');
    //auth.logout();
  } else {
    // bugsnag?
    console.warn('Unhandled error: ', error);
  }
}