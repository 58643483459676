const PNT = require('google-libphonenumber').PhoneNumberType;
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const safeParse = (n, locales) => {

  const empty = /^0$/.test(n);

  if (empty) {

    return false;

  } else {
    try {

      for (var i = 0; i < locales.length; i++) {

        const locale = locales[i];
        const parsed = phoneUtil.parseAndKeepRawInput(n, locale);
        const valid = phoneUtil.isValidNumberForRegion(parsed, locale)

        if (valid) {
          return parsed;
        }
      }

      return false;

    } catch (e) {
      console.log(`Could not parse ${n}: `, e);
      return false;
    }
  }
}

export const isValidMobile = (n, locales) => {
  const parsed = safeParse(n, locales);
  if (parsed) {
    return phoneUtil.getNumberType(parsed) === PNT.MOBILE;
  } else {
    return false;
  }
};

export const canonicalize = (n, locales) => {
  const parsed = safeParse(n, locales);
  if (parsed) {
    return phoneUtil.format(parsed, PNF.E164);
  } else {
    return false;
  }
};

export const nationalize = (n, locales) => {
  const parsed = safeParse(n, locales);
  if (parsed) {
    return phoneUtil.format(parsed, PNF.NATIONAL);
  } else {
    return false;
  }
};