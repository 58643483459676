import { navigate } from '@reach/router';

import { bootstrapOtp, verifyOtp } from '../api/otp';
import { onError } from './errors';

export const otpEffects = {
  requestOtp: (e164) => async ({state, setState}) => {
    const { idToken, idTokenExpiry } = state;
    const now = new Date().getTime() / 1000;
    const valid = (idTokenExpiry != undefined) && (now < idTokenExpiry);
    if (!valid) {
      const { auth } = state;
      auth.logout();
    } else {
      const { bootstrapToken, error } =  await bootstrapOtp(e164, idToken);
      if (error) {
        const { auth } = state;
        onError(error, auth);
      } else {
        setState(state => ({ bootstrapToken }));
      }
    }
  },
  verifyOtp: (otp) => async ({state, setState}) => {
    const { bootstrapToken } = state;
    const { accessToken, user, ephemeralToken, error } = await verifyOtp(otp, bootstrapToken);
    if (error) {
      onError(error);
    } else if (accessToken) {
      setState(state => ({ accessToken }));
      navigate('/app/dashboard');
    } else if (ephemeralToken) {
      setState(state => ({ ephemeralToken }));
      navigate('/app/collect');
    }
  },
};

export const otpSelectors = {
  // TODO should this verify the expiry?
  hasValidBootstrapToken: () => state => (state.bootstrapToken != undefined)
};