import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Container } from 'constate';
import { Redirect } from '@reach/router';

import { NumberForm } from '../../components/NumberForm';
import { OtpForm }  from '../../components/OtpForm';

import { otpEffects, otpSelectors } from '../../app/effects/otp';
import { authEffects, authSelectors } from '../../app/effects/auth';

import { canonicalize } from '../../utils/phoneSyntax';
import { locales } from '../../utils/locales';

import { SettingsQuery } from '../../app/graph/queries/SettingsQuery';

import Busy from '../../components/widgets/busy';

import Layout from '../../components/layout';

class ValidateNumber extends Component {

  constructor(props) {
    super(props);
    this.state = {
      number: undefined,
    };
  }

  submitNumber = ({number}) => {
    this.setState({ number, });
    const e164 = canonicalize(number, locales);
    const { requestOtp } = this.props;
    requestOtp(e164);
  }

  submitOtp = ({otp}) => {
    let { verifyOtp } = this.props;
    verifyOtp(otp);
  }

  reset = () => {
    this.setState({number: undefined});
  }

  renderForm() {

    let { number } = this.state;
    const { hasValidBootstrapToken } = this.props;

    if (number && hasValidBootstrapToken()) {
      return (
        <div>
          <div>An SMS code has been sent to {number} *</div>

          <div className='mt3'>
            <OtpForm onSubmit={this.submitOtp} />
          </div>

          <div className='mt5 f6 i' onClick={this.reset}>
            (*) Register different number
          </div>

        </div>
      );
    } else {
      return (
        <NumberForm onSubmit={this.submitNumber} />
      )
    }
  }

  render() {

    let { number } = this.state;
    const step = (number) ?
      'Step 2: Verify your mobile number' :
      'Step 1: Register your mobile number';

    return (
      <div className='row pb4 pt3'>
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-6">
          <h1>Create Account</h1>
          <h3>{step}</h3>
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

const CaptureNumberPage = ({location}) => (
  <Layout>
    <Container
      context='primary'
      selectors={{...otpSelectors, ...authSelectors}}
      effects={{...otpEffects, ...authEffects}}>
      {(props) => {

        const { handleAuth, bootstrapAuthenticated, refreshAttempted } = props;
        handleAuth({location});

        if (bootstrapAuthenticated() && !refreshAttempted) {
          return (<ValidateNumber {...props}/>);
        } else {

          return (
            <Query query={SettingsQuery}>
              {({ loading, error, data }) => {
                if (loading) {
                  return <Busy/>
                } else if (data) {
                  return (
                    <Redirect to='/app/dashboard' noThrow/>
                  )
                } else if (error) {
                  // TODO Handle error?
                  // bugnsag ??
                  return (<ValidateNumber {...props}/>);
                }
              }}
            </Query>
          );
        }
      }}
    </Container>
  </Layout>
);

export default CaptureNumberPage;