import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const validate = otp => {
  if (!otp) {
    return 'Required';
  } else {
    const digits = otp.toString().length;
    if (digits < 4) {
      return 'Code must have 4 numbers';
    }
  }
}

const initialValues = {
  otp: '',
};

export const OtpForm = ({onSubmit}) => {
  const s = (false) ? 'bg-yellow' : 'bg-moon-gray';
  return (
  <Formik 
    initialValues={initialValues}
    onSubmit={onSubmit}>
    {({isSubmitting, isValid}) => {
      const s = (isValid) ? 'bg-yellow' : 'bg-moon-gray';
      return (
        <Form>
          <Field className='ba' name="otp" validate={validate} />
          <ErrorMessage name="otp" component="div" />
          <div className='mt3'>
            <button className={s}
                    type="submit" 
                    disabled={!isValid || isSubmitting}>Validate</button>
          </div>
        </Form>
      )}}
  </Formik>
)};
