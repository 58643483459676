import { handleResponse } from './utils';

const serverURL = process.env.GATSBY_SWSM_ENDPOINT;

export const bootstrapOtp = async (e164, idToken) => {

  const payload = {
    number: e164,
  };

  const request = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer: ' + idToken,
    }
  }

  return fetch(`${serverURL}/otp/bootstrap/request`, request)
  .then(handleResponse)
  .catch((error) => ({ error }));
}

export const verifyOtp = (otp, bootstrapToken) => {

  const payload = {
    otp: otp,
  };

  const request = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer: ' + bootstrapToken,
    }
  }

  return fetch(`${serverURL}/otp/bootstrap/verify`, request)
  .then(handleResponse)
  .catch((error) => ({ error }));
}