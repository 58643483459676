import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { isValidMobile } from '../utils/phoneSyntax';
import { locales } from '../utils/locales';

const validate = number => {
  let error;
  if (!number) {
    error = 'Required'
  } else {
    if (!isValidMobile(number, locales)) {
      error = 'Not a valid mobile number'
    }
  }
  return error;
}

const initialValues = {
  number: '',
};

export const NumberForm = ({onSubmit}) => {
  const s = (false) ? 'bg-yellow' : 'bg-moon-gray';
  return (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}>
    {({isSubmitting, isValid}) => {
      const s = (isValid) ? 'bg-yellow' : 'bg-moon-gray';
      return (
        <Form>
          <Field className='ba' name="number" validate={validate} />
          <ErrorMessage name="number" component="div" />
          <div className='mt3'>
            <button className={s}
                    type="submit"
                    disabled={!isValid || isSubmitting}>Next</button>
          </div>
        </Form>
      )}}
  </Formik>
)}
